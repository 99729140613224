import React, { Component } from "react";

export default class WhySignup extends Component{

  render(){
    return(
      <div className="WhySignup">
        <article>
          <h1>Why you should become a little mender</h1>
        </article>
      </div>
    );
  }

}
