import React, { Component } from "react";
import './LandingPage.css'

export default class LandingPage extends Component{

  render(){
    return(
      <div className="Home">
        <div className="lander">
          <h1>Little Menders</h1>
        </div>
      </div>
    );
  }
}
