import React, { useState } from "react";
import { API } from "aws-amplify";
import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../components/LoaderButton";

export default function Settings(props) {
  const [isLoading, setIsLoading] = useState(false);

  function billUser(details) {
    return API.post("notes", "/billing", {
      body: details
    });
  }

  return (
    <div className="Settings">
      <LinkContainer to="/settings/email">
        <LoaderButton
          block
          bsSize="large"
          text="Change Email"
        />
      </LinkContainer>
      <LinkContainer to="/settings/password">
        <LoaderButton
          block
          bsSize="large"
          text="Change Password"
        />
      </LinkContainer>
    </div>
  );
}
