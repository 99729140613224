import React from "react";
import { Route, Switch } from "react-router-dom";
import WhatWeDo from "./containers/WhatWeDo";
import Login from "./containers/Login";
import WhySignup from "./containers/WhySignup";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Settings2 from "./containers/Settings2";
import Home2 from "./containers/Home2";

export default function SecondPanelRoutes({ appProps }) {
  return (
    <Switch>
      <UnauthenticatedRoute path="/" exact component={WhatWeDo} appProps={appProps}/>
      <UnauthenticatedRoute path="/signup" exact component={WhySignup} appProps={appProps}/>
      <AuthenticatedRoute path="/Home" exact component={Home2} appProps={appProps}/>
      <AuthenticatedRoute path="/settings" exact component={Settings2} appProps={appProps} />
      <AppliedRoute component={WhatWeDo} appProps={appProps}/>
    </Switch>
  );
}
