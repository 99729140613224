export default {
  s3: {
    REGION: "eu-west-2",
    BUCKET: "littlemenders.com"
  },
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_TYRoaj5An",
    APP_CLIENT_ID: "7aa6ntgecidqp8l3jnpbc9just",
    IDENTITY_POOL_ID: "eu-west-2:0f3bba89-8a1c-4043-8c4d-314d3860b553"
  }
};
