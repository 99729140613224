import React, { useState, useEffect } from "react";
import { PageHeader, ListGroup } from "react-bootstrap";
import "./Home2.css";

export default function Home2(props) {
  const [isLoading, setIsLoading] = useState(true);

  function renderNotesList(notes) {
    return null;
  }

  return (
    <div className="Home">
      <PageHeader>Welcome to your home page second screen</PageHeader>
    </div>
  );
}
