import React, { useState, useEffect } from "react";
import logo from './logo.svg'
import './App.css'
import image from './bwimage.jpg'
import FirstPanelRoutes from './FirstPanelRoutes'
import SecondPanelRoutes from './SecondPanelRoutes'
import { Auth } from "aws-amplify"
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

function App(props) {

  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [navExpanded, setNavExpanded] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  function setNavExpand(expanded) {
   setNavExpanded(expanded);
  }

  function closeNav (ex) {
    console.log("nav closed");
    setNavExpanded(false);
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    closeNav();
    props.history.push("/login");
  }

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    setIsAuthenticating(false);
  }

  return (
      !isAuthenticating &&
      <div className="App">
            <Navbar onToggle={setNavExpand}
                expanded={navExpanded}>
              <Navbar.Header>
                <Navbar.Brand>
                  <Link to="/">Little Menders</Link>
                </Navbar.Brand>
                <Navbar.Toggle />
              </Navbar.Header>
              <Navbar.Collapse>
                <Nav pullRight>
                  {isAuthenticated
                    ? (
                    <>
                    <LinkContainer to="/settings">
                      <NavItem onSelect={closeNav}>Settings</NavItem>
                    </LinkContainer>
                     <NavItem onClick={handleLogout}>Logout</NavItem>
                     </>
                   ): (<>
                        <LinkContainer to="/signup">
                          <NavItem onSelect={closeNav}>Signup</NavItem>
                        </LinkContainer>
                        <LinkContainer to="/login">
                          <NavItem onSelect={closeNav}>Login</NavItem>
                        </LinkContainer>
                      </>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          <section id="main">
            <FirstPanelRoutes appProps={{ isAuthenticated, userHasAuthenticated }} />
          </section>
          <section id="WhatWeDo">
            <SecondPanelRoutes appProps={{ isAuthenticated, userHasAuthenticated }}/>
          </section>
        </div>
  );
}

export default withRouter(App);
