import React, { useState, useEffect } from "react";
import { PageHeader, ListGroup } from "react-bootstrap";

export default function Settings2(props) {
  const [isLoading, setIsLoading] = useState(true);

  function renderNotesList(notes) {
    return null;
  }

  return (
    <div className="Settings2">
      <PageHeader>Welcome to your settings page second screen</PageHeader>
    </div>
  );
}
