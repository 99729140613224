import React, { Component } from "react";
import './WhatWeDo.css'

export default class WhatWeDo extends Component{

  render(){
    return(
      <div className="WeDo">
        <article>
          <h1>Hello, Welcome to Little Menders. </h1>
          <br></br>
          <p> We, like many people, are passionate about doing what we can to help save our planet. 🌍 </p>
          <p><i>Reduce - Reuse</i></p>
          <p> The idea is that things are thrown out everyday, which, with the help of a skilled, crafts person/mender could be put back into use. This could be anything from mending a hole in a sock, putting elbow patches on the sleeves of your favourite sweater, fixing a belt buckle or a suitcase handle. </p>
          <p>With the help of the internet, we can all be Little Menders - no matter whether we are looking to have something mended or offering our services to mend something. Each mend helps reduce and reuse, saving the earths resources one little ‘mend’ at a time.</p>
        </article>
      </div>
    );
  }

}
